import React from 'react';
import Body, { BodyDiv } from '../layout/Body';
import { DKPageProps } from '../gatsby/createPages';
import { sections } from '../layout/sections';
import { GlobalStyle } from '../style/GlobalStyle';
import styled, { createGlobalStyle } from 'styled-components';
import { resetCSS } from '../style/reset';
import { Helmet } from 'react-helmet';

export type Props = DKPageProps;

const Shrug = styled.div`
	font-size: 19px;
	font-family: Arial, Helvetica, sans-serif;
`;

const ResumeStyle = createGlobalStyle`
	${resetCSS}
	
	body {
		font-family: 'Lato', serif;
		padding-top:0vh;
		padding-bottom:10vh;
		padding-left:5vw;
		padding-right:5vw;
		line-height:24px;
		font-size:14px;
		
		strong {
			/* letter-spacing:.5px; */
		}
	}
	
	strong {
		font-weight:bold;
	}
	
	p {
		margin-bottom:30px;
	}
	
	em {
		font-style: italic;
	}
	
	.with {
		font-style: italic;
	}
	
	.body {
		padding-left: 30px;
		display:inline-block;
		margin-top:20px;
	}
	
	a {
		display:inline-block;
		word-wrap: break-word;
		word-break: break-all;
		&:visited {
			color:blue;
		}
	}
	
	h1 {
		font-weight:bold;
		font-size:19px;
		&:not(.top) {
			margin:60px 0px 30px 0px;
		}
	}
	
`;

const TwoCol = styled.div`
	display: flex;
	max-width: 550px;
	& > div {
		line-height: 40px;
		width: 50%;
	}
`;

export const Resume:React.FC<Props> = props => {
	return (
		<>
			<Helmet>
				<link
					href='https://fonts.googleapis.com/css?family=Lato|Libre+Baskerville|Roboto+Slab|Rubik&display=swap'
					rel='stylesheet'
				/>
			</Helmet>
			<ResumeStyle />
			<div>
				<br />
				<br />
				<h1 className='top'>David Kraftsow</h1>
				Technologist for hire
				<br />
				<br />
				<TwoCol>
					<div>
						Web <a href={`https://davidkraftsow.com`}>davidkraftsow.com</a>
						<br />
						Email <a href={`mailto:dontsave@gmail.com`}>dontsave@gmail.com</a>
						<br />
						GitHub <a href={`https://github.com/dontsave`}>@dontsave</a>
					</div>
					<div>
						Phone <a href='tel:917-720-6371'>917-720-6371</a>
						<br />
						Twitter <a href={`https://twitter.com/dontsave`}>@dontsave</a>
					</div>
				</TwoCol>
			</div>
			<h1>About</h1>
			<BodyDiv>{sections['about']}</BodyDiv>
			<h1>Stack</h1>
			<BodyDiv>{sections['stack']}</BodyDiv>
			<h1>Projects</h1>
			<BodyDiv>{sections['projects']}</BodyDiv>
			<br />
			<br />
			<br />
			<h1>Press</h1>
			<BodyDiv>{sections['press']}</BodyDiv>
			<br />
			<Shrug>¯\_(ツ)_/¯</Shrug>
		</>
	);
};

export default Resume;
