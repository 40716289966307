import React, { ReactNode } from 'react';
import { DKPageProps } from '../gatsby/createPages';
import { sections } from './sections';
import styled from 'styled-components';

export type Props = DKPageProps;

export const BodyDiv = styled.div`
	max-width: 620px;
	overflow-x: hidden;
`;

export const Body:React.FC<Props> = props => {
	const page = props.pageContext.page;

	return <BodyDiv>{sections[page]}</BodyDiv>;
};

export default Body;
